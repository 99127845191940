import { useContext } from "react";
import produce from "immer";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import qs from "qs";

import { StateContext } from "../contexts/StateContext";

const useOutboardsStateContext = () => {
  const [state, setState] = useContext(StateContext);

  const applyState = () => {
    setState(
      produce(state, (draftState) => {
        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const resetState = () => {
    setState(
      produce(state, (draftState) => {
        draftState.changeCount = state.changeCount + 1;

        // filters
        draftState.filters.hp = "";
        draftState.filters.year = { min: "", max: "" };
        draftState.filters.length = { min: "", max: "" };
        draftState.filters.price = { min: "", max: "" };
        draftState.filters.source = "Inventory";

      })
    );
  };

  const getUrl = () => {
    return "search/"
  };

  const getFiltersRequest = () => {
    let request = {};

    request["$filter"] = "WebUnit eq 1 and UnitType eq 'Motor' ";

    // hp
    if (!isEmpty(state.filters.hp)) {
      switch (state.filters.hp) {
        default:
          break;
        case "2.5-25":
          request["$filter"] = request["$filter"].concat("and HP ge 2.5M and HP le 25 ");
          break;
        case "30-115":
          request["$filter"] = request["$filter"].concat("and HP ge 30 and HP le 115 ");
          break;
        case "150-350":
          request["$filter"] = request["$filter"].concat("and HP ge 150 and HP le 350 ");
          break;
        case "400-450":
          request["$filter"] = request["$filter"].concat("and HP ge 400 and HP le 450 ");
          break;
      }
    }

    // year
    if (isNumber(state.filters.year.min) || isNumber(state.filters.year.max)) {
      const minYear = !isNumber(state.filters.year.min) ? 2015 : state.filters.year.min;
      const maxYear = !isNumber(state.filters.year.max) ? 2021 : state.filters.year.max;

      request["$filter"] = request["$filter"].concat(`and ModelYear ge ${minYear} and ModelYear le ${maxYear} `);
    }

    request["$orderby"] = "HP";

    return request;
  };

  const getKey = () => {
    let request = {};

    Object.assign(request, getFiltersRequest());

    return qs.stringify(request);
  };

  const setHP = (value) => {
    setState(
      produce(state, (draftState) => {
        draftState.filters.hp = value;

        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const setYear = (value, range = "min") => {
    if (range.indexOf("min") === 0) {
      setState(
        produce(state, (draftState) => {
          draftState.filters.year.min = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    } else {
      setState(
        produce(state, (draftState) => {
          draftState.filters.year.max = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    }
  };

  return {
    state,
    applyState,
    resetState,
    getUrl,
    getKey,
    setYear,
    setHP,
  };
};

export default useOutboardsStateContext;
