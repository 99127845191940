import { useContext } from "react";
import produce from "immer";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import qs from "qs";

import { StateContext } from "../contexts/StateContext";

const useBoatsStateContext = () => {
  const [state, setState] = useContext(StateContext);

  const applyState = () => {
    setState(
      produce(state, (draftState) => {
        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const resetState = () => {
    setState(
      produce(state, (draftState) => {
        draftState.changeCount = state.changeCount + 1;

        // filters
        draftState.filters.maker = [];
        draftState.filters.year = { min: "", max: "" };
        draftState.filters.length = { min: "", max: "" };
        draftState.filters.price = { min: "", max: "" };
        draftState.filters.source = "Inventory";

        draftState.pagination.offset = 0;
      })
    );
  };

  const getFiltersRequest = () => {
    let request = {};

    // maker
    if (!isEmpty(state.filters.maker)) {
      request["make"] = state.filters.maker.join(",");
    }

    // condition
    if (!isEmpty(state.filters.condition)) {
      request["condition"] = state.filters.condition;
    }

    // year
    if (isNumber(state.filters.year.min) || isNumber(state.filters.year.max)) {
      const minYear = !isNumber(state.filters.year.min) ? 2010 : state.filters.year.min;
      const maxYear = !isNumber(state.filters.year.max) ? 2020 : state.filters.year.max;

      request["year"] = minYear + ":" + maxYear;
    }

    // length
    if (!isEmpty(state.filters.length.min) || !isEmpty(state.filters.length.max)) {
      const minLength = isEmpty(state.filters.length.min) ? 25 : state.filters.length.min.value;
      const maxLength = isEmpty(state.filters.length.max) ? 60 : state.filters.length.max.value;

      request["length"] = minLength + ":" + maxLength + "|feet";
    }

    // price
    if (!isEmpty(state.filters.price.min) || !isEmpty(state.filters.price.max)) {
      const minPrice = isEmpty(state.filters.price.min) ? 75000 : state.filters.price.min.value;
      const maxPrice = isEmpty(state.filters.price.max) ? 2000000 : state.filters.price.max.value;

      request["price"] = minPrice + ":" + maxPrice;
    }

    return request;
  }

  const getSearchUrl = (source) => (source.indexOf("Inventory") === 0 ? "boat-inventory/search/" : "boat-services/search/");
  
  const getDetailsUrl = (source) => (source.indexOf("Inventory") === 0 ? "boat-inventory/details/" : "boat-services/details/");

  const getKey = () => {
    let request = {};

    request["rows"] = process.env.REACT_APP_PAGE_SIZE;
    request["offset"] = state.pagination.offset;

    Object.assign(request, getFiltersRequest());

    return qs.stringify(request);
  };

  const setMaker = (value) => {
    setState(
      produce(state, (draftState) => {
        draftState.filters.maker = value;

        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const setYear = (value, range = "min") => {
    if (range.indexOf("min") === 0) {
      setState(
        produce(state, (draftState) => {
          draftState.filters.year.min = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    } else {
      setState(
        produce(state, (draftState) => {
          draftState.filters.year.max = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    }
  };

  const setLength = (value, range = "min") => {
    if (range.indexOf("min") === 0) {
      setState(
        produce(state, (draftState) => {
          draftState.filters.length.min = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    } else {
      setState(
        produce(state, (draftState) => {
          draftState.filters.length.max = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    }
  };

  const setPrice = (value, range = "min") => {
    if (range.indexOf("min") === 0) {
      setState(
        produce(state, (draftState) => {
          draftState.filters.price.min = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    } else {
      setState(
        produce(state, (draftState) => {
          draftState.filters.price.max = value;

          draftState.changeCount = state.changeCount + 1;
        })
      );
    }
  };

  const setCondition = (value) => {
    setState(
      produce(state, (draftState) => {
        draftState.filters.condition = value;

        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const setSource = (value) => {
    setState(
      produce(state, (draftState) => {
        draftState.filters.source = value;
        draftState.pagination.offset = 0;

        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const setOffset = (value) => {
    setState(
      produce(state, (draftState) => {
        draftState.pagination.offset = value;

        draftState.changeCount = state.changeCount + 1;
      })
    );
  };

  const setTotal = (value) => {
    setState(
      produce(state, (draftState) => {
        draftState.pagination.total = value;
      })
    );
  };

  return {
    state,
    applyState,
    resetState,
    getSearchUrl,
    getDetailsUrl,
    getKey,
    getFiltersRequest,
    setMaker,
    setYear,
    setLength,
    setPrice,
    setCondition,
    setSource,
    setOffset,
    setTotal,
  };
};

export default useBoatsStateContext;
