import React, { useState, useEffect } from "react";

const Checkfront = () => {
  const loaderStyle = {
    backgroundImage: 'url("//xxxxxxxx.checkfront.com/images/loader.gif")',
    backgroundOrigin: "left",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "5px 5px 5px 20px",
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    new DROPLET.Widget({
      host: "cannonsmarina.checkfront.com",
      target: "CHECKFRONT_WIDGET_01",
      options: "tabs",
      provider: "droplet",
    }).render();
  }, []);

  return (
    <div id="CHECKFRONT_WIDGET_01">
      <p id="CHECKFRONT_LOADER" style={loaderStyle}>
        Searching Availability...
      </p>
    </div>
  );
};

export default Checkfront;
