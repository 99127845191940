import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import theme from "./theme/theme";
import Notification from "./components/Notification";
import Home from "./containers/Home";
import OutboardsSale from "./containers/OutboardsSale";
import BoatsSale from "./containers/BoatsSale";
import Service from "./containers/Service";
import Rentals from "./containers/Rentals";
import Chat from "./containers/Chat";
import { StateContextProvider } from "./contexts/StateContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";

import useStyles from "./hooks/useStyles";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <NotificationContextProvider>
        <Notification />

        <Container fixed className={classes.appContainer}>
          <Router>
            <Switch>
              <Route path="/chat">
                <Chat />
              </Route>
              <Route path="/rentals">
                <Rentals />
              </Route>
              <Route path="/service">
                <Service />
              </Route>
              <Route path="/outboards">
                <OutboardsSale />
              </Route>
              <Route path="/boats">
                <BoatsSale />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Router>
        </Container>
      </NotificationContextProvider>
    </ThemeProvider>
  );
};

export default App;
