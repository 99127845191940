import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "../../hooks/useStyles";

const AppHeaderOutboards = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={RouterLink} to="/">
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.grow}>
            Outboards
          </Typography>
          <IconButton edge="end" color="inherit" aria-label="filters" onClick={props.onClickFilters}>
            <FilterListIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
};

export default AppHeaderOutboards;
