import React, { Fragment, useState } from "react";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "../hooks/useStyles";
import Image from "./Image";

const TransitionGrow = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const ZoomImage = (props) => {
  const classes = useStyles();
  const [zoomOpen, setZoomOpen] = useState(false);
  
  const handleClose = () => {
    setZoomOpen(false);
  };

  const handleOpen = () => {
    setZoomOpen(true);
  }

  return (
    <Fragment>
    <Image src={props.src} alt={props.title} className={props.className} onClick={handleOpen} />
    {(zoomOpen) ? 
    <Dialog fullScreen open={zoomOpen} onClose={handleClose} TransitionComponent={TransitionGrow}>
      <div className={classes.detailsZoomImageWrapper}>
        <Box position="absolute" top={10} left={10} zIndex="modal">
          <IconButton aria-label="close" onClick={handleClose} className={classes.detailsCloseButton}>
            <CloseIcon />
          </IconButton>
        </Box>
        <img src={props.src} alt={props.title} />
      </div>
    </Dialog> : null}
    </Fragment>
  );
};

export default ZoomImage;