import React, { useState, useEffect, useCallback } from "react";
import is_Empty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import clone from "lodash/clone";
import isNumber from "lodash/isNumber";
import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import axios from "axios";
import qs from "qs";
import useSWR, { useSWRInfinite } from "swr";
import useOutboardsStateContext from "./useOutboardsStateContext";


const getOutboards = async (url) => {
  const formatResult = () => {
    let newResults = [],
      numResults = 0;

    if (result && result?.data) {
      newResults = result.data;
      numResults = result?.data?.length ? result.data.length : 0;
    }

    return { data: newResults, total: numResults };
  };

  const result = await axios({
    baseURL: process.env.REACT_APP_OUTBOARDS_API_URL,
    method: "get",
    url: url,
  });

  return formatResult();
};

const getOutboard = async (url) => {
  const formatResult = () => {
    if (result && result?.data) {
      if (result?.data?.results !== "undefinded" && result?.data?.numResults > 0) {
        return result.data.results[0];
      } else if (result?.data?.data?.results !== "undefined" && result?.data?.data?.numResults > 0) {
        return result.data.data.results[0];
      }
    }
  };

  const result = await axios({
    baseURL: process.env.REACT_APP_OUTBOARDS_API_URL,
    method: "get",
    url: url,
  });

  return formatResult();
};

const useOutboards = () => {
  const { getUrl, getKey } = useOutboardsStateContext();

  const url = getUrl() + "?" + getKey();

  const { data, error } = useSWR(url, getOutboards);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export { useOutboards };
