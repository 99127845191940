import React, { Fragment, useState } from "react";
import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import uniqueId from "lodash/uniqueId";

import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import useStyles from "../../hooks/useStyles";
import { useBoat } from "../../hooks/useBoats";
import ZoomImage from "../ZoomImage";
import Image from "../Image";
import { formatCurrency } from "../../utils/Currency";

const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BoatGallery = (props) => {
  const classes = useStyles();

  return (
    <GridList cellHeight={200} spacing={1} className={classes.detailsGallery}>
      {props.images.map((img, index) => (
        <GridListTile key={img.Uri} cols={index % 5 === 0 ? 2 : 1} rows={index % 5 === 0 ? 2 : 1}>
          <ZoomImage src={img.Uri} alt={props.title} className={classes.detailsGalleryImage} />
        </GridListTile>
      ))}
    </GridList>
  );
};

const BoatDetails = (props) => {
  const classes = useStyles();

  const { data, isLoading } = useBoat(props.source, props.data.DocumentID);

  return (
    <Fragment>
      {isArray(props.data.Images) ? <Image className={classes.detailsBoatImage} alt={props.data.MakeStringExact + " " + props.data.ModelExact} src={props.data.Images[0].Uri} /> : null}
      <Box position="absolute" top={10} left={10} zIndex="modal">
        <IconButton aria-label="close" onClick={props.onClose} className={classes.detailsCloseButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div className={classes.spacerTop} />
      <Container>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography gutterBottom variant="h5" component="h2">
              <strong>
                {props.data.MakeStringExact} {props.data.ModelExact}
              </strong>{" "}
              | {props.data.ModelYear}
            </Typography>
            <Typography gutterBottom variant="h5" component="h3">
              {props.data.NominalLength} | {props.data.SaleClassCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textPrimary" component="p">
              Price: {!isUndefined(props.data.PriceHideInd) && props.data.PriceHideInd === false ? formatCurrency.format(props.data.NormPrice) : "Call for price"}
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p">
              Location: {props.data.BoatLocation.BoatCityName}
            </Typography>
          </Grid>
          {data ? (
            <Fragment>
              <div className={classes.spacerTop} />
              <Grid item>
                <Typography variant="body2" color="textSecondary" component="h5">
                  DETAILS
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: data.GeneralBoatDescription[0] }} />
              </Grid>
              {data?.Engines?.length > 0 && data?.Engines[0]?.Make && data?.Engines[0]?.Model && data?.Engines[0]?.Year ? (
                <Grid item>
                  <Typography variant="body2" color="textSecondary" component="h5" style={{ marginBottom: "1em" }}>
                    ENGINES
                  </Typography>
                  <List dense={true}>
                    {data.Engines.map((engine) => (
                      <ListItem key={uniqueId()}>
                        <ListItemIcon>
                          <ArrowForwardIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={engine.Make + " " + engine.Model + " | " + engine.Year}
                          secondary={
                            <Fragment>
                              <Typography component="span" variant="body2">
                                {engine.Type ? "Type: " + engine.Type + " " : null}
                                {engine.Hours ? "Hours: " + engine.Hours : null}
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2">
                                {engine.EnginePower ? "Power: " + engine.EnginePower : null}
                              </Typography>
                            </Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ) : null}
              {data?.Images?.length > 0 ? (
                <Grid item>
                  <Typography variant="body2" color="textSecondary" component="h5" style={{ marginBottom: "1em" }}>
                    GALLERY
                  </Typography>
                  <BoatGallery images={data.Images} title={data.MakeStringExact + " " + data.ModelExact} />
                </Grid>
              ) : null}
            </Fragment>
          ) : isLoading ? (
            <Grid container justify="center" className={classes.spacerTop}>
              <CircularProgress className={classes.progress} size={30} />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Fragment>
  );
};

const BoatItem = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const data = props.data;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Card className={classes.boatCard}>
        <CardActionArea onClick={handleOpen}>
          <CardMedia className={classes.boatCardImage} component="img" image={isArray(data.Images) ? data.Images[0].Uri : ""} title={data.MakeStringExact + " " + data.ModelExact} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              <strong>
                {data.MakeStringExact} {data.ModelExact}
              </strong>{" "}
              | {data.ModelYear}
            </Typography>
            <Typography gutterBottom variant="h5" component="h4">
              {data.NominalLength} | {data.SaleClassCode}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              Price: {!isUndefined(data.PriceHideInd) && data.PriceHideInd === false ? formatCurrency.format(data.NormPrice) : "Call for price"}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              Location: {data.BoatLocation.BoatCityName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={TransitionSlideUp}>
        <BoatDetails source={props.source} data={data} onClose={handleClose} />
      </Dialog>
    </Fragment>
  );
};

export default BoatItem;
