import React, { useEffect, useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";

import useStateContext from "../../hooks/useBoatsStateContext";
import useStyles from "../../hooks/useStyles";

const SelectMaker = (props) => {
  const { state, applyState, resetState, setMaker, setYear, setLength, setPrice, setCondition, setSource, setOffset } = useStateContext();
  const theme = useTheme();
  const classes = useStyles();

  const onClickMaker = (event) => {
    const makers = [...state.filters.maker];
    console.log(event.target.checked);
    if (makers.indexOf(event.target.value) === -1) {
      if (event.target.checked) makers.push(event.target.value);
    } else {
      if (!event.target.checked) makers.splice(makers.indexOf(event.target.value), 1);
    }

    props.onChange(makers);
  }

  return (
    <List disablePadding={true} component="div">
      {state.defaults.maker
        ? state.defaults.maker.map((item) => (
            <ListItem key={item} component="div">
              <Checkbox value={item} checked={props.value.indexOf(item) > -1} onClick={onClickMaker}/>
              <ListItemText primary={item} />
            </ListItem>
          ))
        : ""}
    </List>
  );
};

export default SelectMaker;
