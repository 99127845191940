import { useContext } from "react";

import { NotificationContext } from "../contexts/NotificationContext";

const useNotification = () => {
  const [message, addMessage] = useContext(NotificationContext);

  const removeMessage = () => {
    addMessage(null);
  }

  return { message, addMessage, removeMessage };
};

export default useNotification;
