import React, { useEffect, useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";

import useStateContext from "../../hooks/useBoatsStateContext";
import useStyles from "../../hooks/useStyles";

const SelectCondition = (props) => {
  const { state, applyState, resetState, setMaker, setYear, setLength, setPrice, setCondition, setSource, setOffset } = useStateContext();
  const theme = useTheme();
  const classes = useStyles();

  return (
    <List disablePadding={true} component="div">
    <ListItem component="div" >
    <FormControl className={classes.formControl}>
      <RadioGroup aria-label="condition" name="condition" value={props.value} onChange={props.onChange}>
        <FormControlLabel value="" control={<Radio />} label="All" />
        <FormControlLabel value="New" control={<Radio />} label="New" />
        <FormControlLabel value="Used" control={<Radio />} label="Used" />
      </RadioGroup>
    </FormControl>
    </ListItem>
    </List>
  );
};

export default SelectCondition;
