import React, { useState, Fragment } from "react";
import SelectMaker from "./SelectMaker";
import useBoatsStateContext from "../../hooks/useBoatsStateContext";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import useStyles from "../../hooks/useStyles";

import SelectSource from "./SelectSource";
import SelectCondition from "./SelectCondition";
import SelectYear from "./SelectYear";
import SelectLength from "./SelectLength";
import SelectPrice from "./SelectPrice";
import { Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListSubheader, ListItemText } from "@material-ui/core";

const SearchFilters = (props) => {
  const { state, applyState, resetState, setMaker, setYear, setLength, setPrice, setCondition, setSource, setOffset } = useBoatsStateContext();

  const [openMake, setOpenMake] = useState(false);
  const classes = useStyles();

  const [maker, setMakerValue] = useState(state.filters.maker);
  const [condition, setConditionValue] = useState(state.filters.condition);

  const [minYear, setMinYearValue] = useState(state.filters.year.min);
  const [maxYear, setMaxYearValue] = useState(state.filters.year.max);

  const [minLength, setMinLengthValue] = useState(state.filters.length.min);
  const [maxLength, setMaxLengthValue] = useState(state.filters.length.max);

  const [minPrice, setMinPriceValue] = useState(state.filters.price.min);
  const [maxPrice, setMaxPriceValue] = useState(state.filters.price.max);

  const onChangeCondition = (event) => {
    setCondition(event.target.value);
    setConditionValue(event.target.value);
  };

  const onChangeMaker = (maker) => {
    setMaker(maker);
    setMakerValue(maker);
  };

  const onChangeMinYear = (event) => {
    const newYear = event.target.value;

    if (newYear > maxYear) {
      setMaxYearValue("");
    }

    setYear(newYear, "min");
    setMinYearValue(newYear);
  };

  const onChangeMaxYear = (event) => {
    const newYear = event.target.value;

    if (newYear < minYear) {
      setMinYearValue("");
    }

    setYear(newYear, "max");
    setMaxYearValue(newYear);
  };

  const onChangeMinLength = (event) => {
    const newLength = event.target.value;

    if (newLength.value > maxLength.value) {
      setMaxLengthValue("");
    }

    setLength(newLength, "min");
    setMinLengthValue(newLength);
  };

  const onChangeMaxLength = (event) => {
    const newLength = event.target.value;

    if (newLength.value < minLength.value) {
      setMinLengthValue("");
    }

    setLength(newLength, "max");
    setMaxLengthValue(newLength);
  };

  const onChangeMinPrice = (event) => {
    const newPrice = event.target.value;

    if (newPrice.value > maxPrice.value) {
      setMaxPriceValue("");
    }

    setPrice(newPrice, "min");
    setMinPriceValue(newPrice);
  };

  const onChangeMaxPrice = (event) => {
    const newPrice = event.target.value;

    if (newPrice.value < minPrice.value) {
      setMinPriceValue("");
    }

    setPrice(newPrice, "max");
    setMaxPriceValue(newPrice);
  };

  const onReset = (event) => {
    setMakerValue([]);

    setConditionValue("");

    setMinYearValue("");
    setMaxYearValue("");

    setMinLengthValue("");
    setMaxLengthValue("");

    setMinPriceValue("");
    setMaxPriceValue("");

    resetState();
  };

  const onClickMake = () => {
    setOpenMake(!openMake);
  };

  return (
    <List className={classes.drawerBoatsFilters}>
      <ListItem>
        <ListItemText edge="start" primary="Search Filters" primaryTypographyProps={{ component: "h3", variant: "h5" }} />
        <ListItemSecondaryAction>
          <IconButton onClick={props.onClose} edge="end">
            <CloseIcon style={{ marginLeft: "auto", marginRight: 0 }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Divider />

      <ListItem>
        <ListItemText primary="Condition" />
      </ListItem>
      <ListItem>
        <SelectCondition value={condition} onChange={onChangeCondition} />
      </ListItem>

      <ListItem style={{ marginTop: 20 }} button onClick={onClickMake}>
        <ListItemText primary="Make" />
        {openMake ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openMake} timeout="auto" unmountOnExit>
        <SelectMaker value={maker} onChange={onChangeMaker} />
      </Collapse>

      <ListItem style={{ marginTop: 20 }}>
        <ListItemText primary="Price" />
      </ListItem>
      <ListItem>
        <SelectPrice minValue={minPrice} maxValue={maxPrice} onChangeMin={onChangeMinPrice} onChangeMax={onChangeMaxPrice} />
      </ListItem>

      <ListItem style={{ marginTop: 20 }}>
        <ListItemText primary="Year" />
      </ListItem>
      <ListItem>
        <SelectYear minValue={minYear} maxValue={maxYear} onChangeMin={onChangeMinYear} onChangeMax={onChangeMaxYear} />
      </ListItem>

      <ListItem style={{ marginTop: 20 }}>
        <ListItemText primary="Length" />
      </ListItem>
      <ListItem>
        <SelectLength minValue={minLength} maxValue={maxLength} onChangeMin={onChangeMinLength} onChangeMax={onChangeMaxLength} />
      </ListItem>
    </List>
  );
};

export default SearchFilters;
