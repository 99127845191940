import React, { useEffect, useState, Fragment, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useInView } from "react-intersection-observer";

import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import uniqueId from "lodash/uniqueId";
import axios from "axios";

import useBoatsStateContext from "../../hooks/useBoatsStateContext";
import useSessionState from "../../hooks/useSessionState";
import useStyles from "../../hooks/useStyles";
import useDebounce from "../../hooks/useDebounce";
import useNotification from "../../hooks/useNotification";
import BoatItem from "./BoatItem";
import { useInfiniteBoats } from "../../hooks/useBoats";

const BoatsPage = (props) => {
  return isArray(props.items.data)
    ? props.items.data.map((item) => (
        <Grid item xs={12} sm={6} key={item.DocumentID}>
          <BoatItem data={item} source={props.source} />
        </Grid>
      ))
    : null;
};

const BoatList = (props) => {
  const { message, addMessage, removeMessage } = useNotification();

  const classes = useStyles();

  const { state } = useBoatsStateContext();
  const { data, isLoadingInitialData, isLoadingMore, isEmpty, isReachingEnd, isError, size, setSize } = useInfiniteBoats(props.source);

  if (isError) {
    addMessage(isError);
  }

  const loadMore = () => {
    setSize(size + 1);
  };

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView && !isReachingEnd) {
      loadMore();
    }
  }, [inView]);

  return data ? (
    <Grid container spacing={3} direction="row" justify="center" alignItems="center">
      {data.map((boats) => (
        <BoatsPage key={uniqueId()} items={boats} source={props.source} />
      ))}

      {!isEmpty && !isReachingEnd ? (
        <div className={classes.textCenter}>
          <Button ref={ref} className={classes.infiniteScroll}>
            LOAD MORE
          </Button>
        </div>
      ) : null}
      
      <Grid item xs={12} className={classes.textCenter}>
        {isLoadingMore ? <CircularProgress className={classes.progress} size={30} /> : null}
        {isEmpty ? <Typography className={classes.textCenter}>No boats found</Typography> : null}
        {isReachingEnd && !isLoadingMore ? <Typography className={classes.textCenter}>No more boats</Typography> : null}
      </Grid>
    </Grid>
  ) : (
    <Backdrop className={classes.backdrop} open={isLoadingInitialData && !isEmpty}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BoatList;
