import React, { useState, Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SvgIcon from "@material-ui/core/SvgIcon";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import { ReactComponent as Logo } from "../../images/logo-mobile.svg";
import useStyles from "../../hooks/useStyles";
import AppMenu from "../Home/AppMenu";

const AppHeaderHome = () => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);

  const toggleMenu = () => {
    setOpened(!opened);
  };

  const toggleOpen = () => {
    setOpened(true);
  };

  const toggleClose = () => {
    setOpened(false);
  };


  return (
    <Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />
          <SvgIcon component={Logo} viewBox="0 0 267 38" className={classes.logo} />
          <div className={classes.grow} />
          <Button color="inherit" style={{display: "none"}}>Login</Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <SwipeableDrawer anchor="left" open={opened} onClose={toggleClose} onOpen={toggleOpen}>
        <AppMenu />
      </SwipeableDrawer>     
    </Fragment>
  );
};

export default AppHeaderHome;
