import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import { PNThin, PNLight, PNRegular, PNSemibold, PNBold, PNBlack } from "./fonts";

let theme = createMuiTheme({
  root: {
    color: "#001f43",
  },
  palette: {
    primary: {
      main: "#001f43",
    },
    secondary: {
      main: "#001f43",
    },
  },
  typography: {
    fontFamily: "Proxima-Nova, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [PNThin, PNLight, PNRegular, PNSemibold, PNBold, PNBlack],
      },
    },
    MuiAppBar: {

    }
  },
});

theme = responsiveFontSizes(theme);

export default theme;