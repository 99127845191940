import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  offsetHeader: theme.mixins.toolbar,
  formControl: {
    //margin: theme.spacing(1),
    minWidth: "130px !important",
    color: "#001f43",
  },
  inputLabel: {
    color: "#001f43",
  },
  palette: {
    primary: {
      light: "#001f43",
      main: "#001f43",
      dark: "#001f43",
      contrastText: "#ffffff",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  blueButton: {
    background: "#001f43 !important",
    color: "#ffffff !important",
  },
  whiteButton: {
    background: "#ffffff !important",
    color: "#001f43 !important",
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      width: 180,
    },
    [theme.breakpoints.up("sm")]: {
      width: 250,
    },
    height: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  flexContainer: {
    display: "flex",
  },
  homeCard: {
    maxWidth: 345,
    backgroundColor: "#ffffff",
    color: "#001f43",
    borderColor: "#001f43",
    marginLeft: "auto",
    marginRight: "auto",
  },
  homeCardMedia: {
    paddingTop: "56.25%",
  },
  infiniteScroll: {
    visibility: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#001f43',
  },
  progress: {
    color: '#001f43',
  },
  drawerBoatsFilters: {
    width: 300,
    padding: theme.spacing(0,1),
  },
  drawerAppMenu: {
    width: 200,
    margin: theme.spacing(1),
  },
  drawerHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
  },
  boatCard: {
    backgroundColor: "#ffffff",
    color: "#001f43",
    //marginBottom: 30,
    maxWidth: 350,
  },
  boatCardImage: {
    height: 200,
    objectFit: "cover",
  },
  outboardCardImage: {
    height: 200,
    objectFit: "contain",
    paddingTop: 20
  },
  spacerTop: {
    marginTop: theme.spacing(2),
  },
  spacerBottom: {
    marginBottom: theme.spacing(2),
  },
  boatDetails: {
    border: "none",
    backgroundColor: "#ffffff",
    color: "#001f43",
  },
  detailsBoatImage: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    maxHeight: "50vh"
  },
  detailsGallery: {
    width: "100%",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  detailsGalleryImage: {
    width: "100%",
    height: 400,
    objectFit: "cover",
  },
  detailsOutboardGalleryImage: {
    width: "100%",
    maxHeight: 400,
    objectFit: "contain",
  },
  galleryPlaceholderContainer: {
    backgroundColor: "#e9e9e9",
    width: "100%",
    height: "100%",
  },
  galleryPlaceholderImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  galleryImageContainer: {
    backgroundColor: "transparent"
  },
  detailsZoomImageWrapper: {
    overflow: "hidden",
    height: "100vh",
    width: "100vw",
    overflowX: "auto",
    overflowY: "auto"
  },
  detailsCloseButton: {
    background: "#001f43 !important",
    color: "#ffffff !important",
    opacity: 0.7,
  },
  detailsOutboardImage: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    padding: "20px 40px",
    maxHeight: "50vh"
  },
}));

export default useStyles;
