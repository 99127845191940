import React, { Fragment, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Fade from '@material-ui/core/Fade';

import useStyles from "../hooks/useStyles";

function Image(props) {
  const classes = useStyles();
  const placeholder = `${process.env.PUBLIC_URL}${process.env.REACT_APP_PLACEHOLDER_IMAGE}`;
  const [loaded, setLoaded] = useState(false);
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <div ref={ref} className={loaded ? classes.galleryImageContainer : classes.galleryPlaceholderContainer}>
      {(inView || loaded) ? <Fade in={loaded}><img onLoad={() => setLoaded(true)} src={props.src} alt={props.alt} className={props.className} style={{ display: loaded ? "block" : "none" }} onClick={props.onClick ? props.onClick : null} /></Fade> : null}
      <img src={placeholder} alt={props.alt} className={classes.galleryPlaceholderImage} style={{ display: loaded ? "none" : "block" }} />
    </div>
  );
}

export default Image;
