import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Slide from "@material-ui/core/Slide";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import useStyles from "../../hooks/useStyles";

const ServiceCard = (props) => {
  const classes = useStyles();
  const [mount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  return (
    <Slide direction={props.slide} in={mount} mountOnEnter unmountOnExit>      
      <Card className={classes.homeCard}>
        <CardActionArea component={Link} to="/service" disabled={true}>
          <CardMedia className={classes.homeCardMedia} image={process.env.PUBLIC_URL + "/images/service.jpg"} title="Yamaha Service Center" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Service
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Slide>
  );
};

export default ServiceCard;
