import React, { useEffect, useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";

import useStateContext from "../../hooks/useBoatsStateContext";
import useStyles from "../../hooks/useStyles";

const SelectPrice = (props) => {
  const { state, applyState, resetState, setMaker, setYear, setLength, setPrice, setCondition, setSource, setOffset } = useStateContext();
  const theme = useTheme();
  const classes = useStyles();

  return (
    <List disablePadding={true} component="div">
    <ListItem component="div" >
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.inputLabel}>Min</InputLabel>
        <Select value={props.minValue} displayEmpty input={<Input />} renderValue={(selected) => selected.label} onChange={props.onChangeMin}>
        <MenuItem value="">None</MenuItem>
          {state.defaults.price
            ? state.defaults.price.map((item) => (
                <MenuItem key={item.value} value={item}>
                  <Checkbox checked={props.minValue.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))
            : ""}
        </Select>
      </FormControl>
      </ListItem>
      <ListItem component="div" >
      <FormControl className={classes.formControl}>
      <InputLabel className={classes.inputLabel}>Max</InputLabel>
        <Select value={props.maxValue} displayEmpty input={<Input />} renderValue={(selected) => selected.label} onChange={props.onChangeMax}>
        <MenuItem value="">None</MenuItem>
          {state.defaults.price
            ? state.defaults.price.map((item) => (
                <MenuItem key={item.value} value={item}>
                  <Checkbox checked={props.maxValue.value === item.value} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))
            : ""}
        </Select>
      </FormControl>
      </ListItem>
    </List>
  );
};

export default SelectPrice;
