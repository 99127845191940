import React, { Fragment, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";


import SearchFilters from "../components/BoatsSale/SearchFilters";
import BoatList from "../components/BoatsSale/BoatList";

import { StateContextProvider } from "../contexts/StateContext";
import AppHeaderBoats from "../components/BoatsSale/AppHeaderBoats";
import useStyles from "../hooks/useStyles";

const initialState = {
  defaults: {
    maker: ["Boston Whaler", "Cobia", "Contender", "Everglades", "Grady-White", "Hydra Sports", "Intrepid", "Jupiter", "Mag Bay", "Pursuit", "Scout", "Southport", "Valhalla"],
    year: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
    length: [
      { label: "25'", value: 25 },
      { label: "30'", value: 30 },
      { label: "35'", value: 35 },
      { label: "40'", value: 40 },
      { label: "45'", value: 45 },
      { label: "50'", value: 50 },
      { label: "55'", value: 55 },
      { label: "60'", value: 60 },
    ],
    price: [
      { label: "$75k", value: 75000 },
      { label: "$100k", value: 100000 },
      { label: "$200k", value: 200000 },
      { label: "$300k", value: 300000 },
      { label: "$400k", value: 400000 },
      { label: "$500k", value: 500000 },
      { label: "$600k", value: 600000 },
      { label: "$700k", value: 700000 },
      { label: "$800k", value: 800000 },
      { label: "$900k", value: 900000 },
      { label: "$1M", value: 1000000 },
      { label: "$1.25M", value: 1250000 },
      { label: "$1.5M", value: 1500000 },
      { label: "$1.75M", value: 1750000 },
      { label: "$2M", value: 2000000 },
    ],
    condition: ["New", "Used"],
    source: ["Inventory", "Brokerage"],
  },
  filters: {
    maker: ["Grady-White"],
    year: { min: "", max: "" },
    length: { min: "", max: "" },
    price: { min: "", max: "" },
    condition: "",
  },
  pagination: {
    offset: 0,
    total: 0,
  },
  changeCount: -1,
};

function TabPanel(props) {
  return (
    <div role="tabpanel" hidden={props.value !== props.index} id={`simple-tabpanel-${props.index}`} aria-labelledby={`simple-tab-${props.index}`}>
      {props.children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BoatsSale = () => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const toggleFilters = () => {
    setOpened(!opened);
  };

  const toggleOpen = () => {
    setOpened(true);
  };

  const toggleClose = () => {
    setOpened(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <StateContextProvider sessionKey="BOATS" initialState={initialState}>
      <AppHeaderBoats onClickFilters={toggleFilters}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="Boat List Source Tabs">
          <Tab label="Inventory" {...a11yProps(0)} />
          <Tab label="Brokerage" {...a11yProps(1)} />
        </Tabs>
      </AppHeaderBoats>

      <SwipeableDrawer anchor="right" open={opened} onClose={toggleClose} onOpen={toggleOpen}>
        <SearchFilters onClose={toggleClose} />
      </SwipeableDrawer>

      <TabPanel value={currentTab} index={0}>
        <BoatList source="Inventory" />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <BoatList source="Brokerage" />
      </TabPanel>
    </StateContextProvider>
  );
};

export default BoatsSale;
