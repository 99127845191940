import React, {useState} from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import { StateContextProvider } from "../contexts/StateContext";
import AppHeaderOutboards from "../components/OutboardsSale/AppHeaderOutboards";
import OutboardList from "../components/OutboardsSale/OutboardList";
import SearchFilters from "../components/OutboardsSale/SearchFilters";

const initialState = {
  defaults: {
    year: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
    hp: [
      { label: "All", value: "All" },
      { label: "2.5 - 25 HP", value: "2.5-25" },
      { label: "30 - 115 HP", value: "30-115" },
      { label: "150 - 350 HP", value: "150-350" },
      { label: "425 HP", value: "400-450" },
    ]
  },
  filters: {
    year: { min: "", max: "" },
    hp: "All",
  },
  changeCount: -1,
};

const OutboardsSale = () => {
  const [opened, setOpened] = useState(false);

  const toggleFilters = () => {
    setOpened(!opened);
  };

  const toggleOpen = () => {
    setOpened(true);
  };

  const toggleClose = () => {
    setOpened(false);
  };
  return (
    <StateContextProvider sessionKey="OUTBOARDS" initialState={initialState}>
      <AppHeaderOutboards onClickFilters={toggleFilters}/>

      <SwipeableDrawer anchor="right" open={opened} onClose={toggleClose} onOpen={toggleOpen}>
        <SearchFilters onClose={toggleClose} />
      </SwipeableDrawer>

      <OutboardList />
    </StateContextProvider>
  );
}

export default OutboardsSale;