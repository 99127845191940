import React from "react";
import useNotification from "../hooks/useNotification";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from "@material-ui/lab/AlertTitle"

const Notification = () => {
  const { message, addMessage, removeMessage } = useNotification();
  
  const onClose = () => {
    removeMessage();
  }

  return (
    (message) ? <Alert severity={message.type} >
      <AlertTitle>Error</AlertTitle>
      {message.message}
    </Alert> : null
  );
}

export default Notification;