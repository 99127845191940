import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListSubheader, ListItemText } from "@material-ui/core";

import useStyles from "../../hooks/useStyles";

const AppMenu = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.drawerAppMenu}>
      <ListItem button component={Link} to="/boats">
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary="Boats" />
      </ListItem>
      <ListItem button component={Link} to="/outboards">
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary="Outboards" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary="Service" />
      </ListItem>
      <ListItem button component={Link} to="/rentals">
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary="Rentals" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary="Events" />
      </ListItem>
      <ListItem button component={Link} to="/chat">
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary="Chat" />
      </ListItem>
    </List>
  );
};

export default AppMenu;
