import React, { useEffect, useState, Fragment, useRef } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import uniqueId from "lodash/uniqueId";

import { useOutboards } from "../../hooks/useOutboards";
import useNotification from "../../hooks/useNotification";
import useStyles from "../../hooks/useStyles";
import useOutboardsStateContext from "../../hooks/useOutboardsStateContext";
import OutboardItem from "./OutboardItem";

const OutboardList = (props) => {
  const { message, addMessage, removeMessage } = useNotification();
  const classes = useStyles();
  const { state } = useOutboardsStateContext();

  const { data, isLoading, isError } = useOutboards();

  if (isError) {
    addMessage(isError);
  }

  return (
    <Fragment>
      {data ? (
        <Grid container spacing={3} direction="row" justify="center" alignItems="center">
          {data.data.map((outboard) => (
            <Grid item xs={12} sm={6} key={uniqueId()}>
              <OutboardItem data={outboard} />
            </Grid>
          ))}
          {data?.total === 0 ? <Typography>No outboards found</Typography> : null}
        </Grid>
      ) : (
        <Backdrop className={classes.backdrop} open={isLoading && !isError}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Fragment>
  );
};

export default OutboardList;
