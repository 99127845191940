import React, { Fragment, useState } from "react";
import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import slugify from "slugify";

import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { positions } from "@material-ui/system";

import useStyles from "../../hooks/useStyles";
import { useBoat } from "../../hooks/useBoats";
import ZoomImage from "../ZoomImage";
import Image from "../Image";
import {formatCurrency} from "../../utils/Currency";

const TransitionSlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OutboardGallery = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <GridList cellHeight={200} spacing={1} className={classes.detailsGallery}>
        {props.images.map((img, index) => (
          <GridListTile key={img.ImageUrl} cols={index % 5 === 0 ? 2 : 1} rows={index % 5 === 0 ? 2 : 1}>
            <ZoomImage src={img.ImageUrl} alt={props.title} className={classes.detailsOutboardGalleryImage} />
          </GridListTile>
        ))}
      </GridList>
    </Fragment>
  );
};

const OutboardDetails = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      {(isArray(props.data.Images) && props.data.Images.length > 0) ? <Image className={classes.detailsOutboardImage} alt={props.data.Make + " " + props.data.Model} src={props.data.Images[0].ImageUrl} /> : <Image className={classes.detailsOutboardImage} alt={props.data.Make + " " + props.data.Model} src={`${process.env.PUBLIC_URL}${process.env.REACT_APP_PLACEHOLDER_IMAGE}`} />}
      <Box position="absolute" top={10} left={10} zIndex="modal">
        <IconButton aria-label="close" onClick={props.onClose} className={classes.detailsCloseButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div className={classes.spacerTop} />
      <Container>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography gutterBottom variant="h5" component="h2">
              <strong>
                {props.data.Make} {props.data.Model}
              </strong>{" "}
              | {props.data.ModelYear}
            </Typography>
            <Typography gutterBottom variant="h5" component="h3">
              {parseInt(props.data.HP)} HP | {props.data.Cylinders} Cyl
            </Typography>
            <Typography gutterBottom variant="h5" component="h3">
              {props.data.Condition}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textPrimary" component="p">
              Price: {!isUndefined(props.data.WebPriceHidden) && props.data.WebPriceHidden === 0 ? formatCurrency.format(props.data.WebPrice) : "Call for price"}
            </Typography>
          </Grid>

          <div className={classes.spacerTop} />
          <Grid item>
            <Typography variant="body2" color="textSecondary" component="h5">
              DETAILS
            </Typography>
            <Typography variant="body2" color="textSecondary" component="h5">
              {props.data.WebDescription}
            </Typography>
          </Grid>
          {props.data?.Images?.length > 0 ? (
            <Grid item>
              <Typography variant="body2" color="textSecondary" component="h5" style={{ marginBottom: "1em" }}>
                GALLERY
              </Typography>
              <OutboardGallery images={props.data.Images} title={props.data.Make + " " + props.data.Model} />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Fragment>
  );
};

const OutboardItem = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Card className={classes.boatCard}>
        <CardActionArea onClick={handleOpen}>
          <CardMedia
            className={classes.outboardCardImage}
            component="img"
            image={(isArray(props.data.Images) && props.data.Images.length > 0) ? props.data.Images[0].ImageUrl : process.env.PUBLIC_URL + process.env.REACT_APP_PLACEHOLDER_IMAGE}
            title={props.data.Make + " " + props.data.Model + " " + props.data.ModelYear}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              <strong>
                {props.data.Make} {props.data.Model}
              </strong>{" "}
              | {props.data.ModelYear}
            </Typography>
            <Typography gutterBottom variant="h5" component="h4">
              {parseInt(props.data.HP)} HP | {props.data.Cylinders} Cyl
            </Typography>
            <Typography gutterBottom variant="h5" component="h3">
              {props.data.Condition}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              Price: {!isUndefined(props.data.WebPriceHidden) && props.data.WebPriceHidden === 0 ? formatCurrency.format(props.data.WebPrice) : "Call for price"}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={TransitionSlideUp}>
        <OutboardDetails source={props.source} data={props.data} onClose={handleClose} />
      </Dialog>
    </Fragment>
  );
};

export default OutboardItem;
