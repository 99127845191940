import React, { useState, useEffect } from "react";
import useScript from "../../hooks/useScript";

const ChatBot = () => {
  const [loaded, error] = useScript("//js.hs-scripts.com/6413474.js");

  useEffect(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
    }
    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.remove();
      }
    };
  }, [loaded]);

  return null;
};

export default ChatBot;
