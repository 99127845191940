import React, { useState, Fragment } from "react";

import useOutboardsStateContext from "../../hooks/useOutboardsStateContext";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "../../hooks/useStyles";

import SelectYear from "./SelectYear";
import SelectHP from "./SelectHP";

import { Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListSubheader, ListItemText } from "@material-ui/core";

const SearchFilters = (props) => {
  const { state, applyState, resetState, setYear, setHP } = useOutboardsStateContext();

  const classes = useStyles();

  const [hp, setHPValue] = useState(state.filters.hp);

  const [minYear, setMinYearValue] = useState(state.filters.year.min);
  const [maxYear, setMaxYearValue] = useState(state.filters.year.max);

  const onChangeHP = (event) => {
    setHP(event.target.value);
    setHPValue(event.target.value);
  };

  const onChangeMinYear = (event) => {
    const newYear = event.target.value;

    if (newYear > maxYear) {
      setMaxYearValue("");
    }

    setYear(newYear, "min");
    setMinYearValue(newYear);
  };

  const onChangeMaxYear = (event) => {
    const newYear = event.target.value;

    if (newYear < minYear) {
      setMinYearValue("");
    }

    setYear(newYear, "max");
    setMaxYearValue(newYear);
  };

  return (
    <List className={classes.drawerBoatsFilters}>
      <ListItem>
        <ListItemText edge="start" primary="Search Filters" primaryTypographyProps={{ component: "h3", variant: "h5" }} />
        <ListItemSecondaryAction>
          <IconButton onClick={props.onClose} edge="end">
            <CloseIcon style={{ marginLeft: "auto", marginRight: 0 }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Divider />

      <ListItem>
        <ListItemText primary="HP" />
      </ListItem>
      <ListItem>
        <SelectHP value={hp} onChange={onChangeHP} />
      </ListItem>

      <ListItem style={{ marginTop: 20 }}>
        <ListItemText primary="Year" />
      </ListItem>
      <ListItem>
        <SelectYear minValue={minYear} maxValue={maxYear} onChangeMin={onChangeMinYear} onChangeMax={onChangeMaxYear} />
      </ListItem>
    </List>
  );
};

export default SearchFilters;
