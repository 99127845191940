import PNThinOTF from '../fonts/Proxima-Nova-Thin-100.otf';
import PNThinWOFF2 from '../fonts/Proxima-Nova-Thin-100.woff2';

import PNLightOTF from '../fonts/Proxima-Nova-Light-300.otf';
import PNLightWOFF2 from '../fonts/Proxima-Nova-Light-300.woff2';

import PNRegularOTF from '../fonts/Proxima-Nova-Reg-400.otf';
import PNRegularWOFF2 from '../fonts/Proxima-Nova-Reg-400.woff2';

import PNSemiboldOTF from '../fonts/Proxima-Nova-Semibold-600.otf';
import PNSemiboldWOFF2 from '../fonts/Proxima-Nova-Semibold-600.woff2';

import PNBoldOTF from '../fonts/Proxima-Nova-Bold-700.otf';
import PNBoldWOFF2 from '../fonts/Proxima-Nova-Bold-700.woff2';

import PNBlackOTF from '../fonts/Proxima-Nova-Black-900.otf';
import PNBlackWOFF2 from '../fonts/Proxima-Nova-Black-900.woff2';

const PNThin = {
  fontFamily: 'Proxima-Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    local('Proxima-Nova'),
    local('Proxima-Nova-Thin'),
    url(${PNThinWOFF2}) format('woff2'),
    url(${PNThinOTF}) format('opentype')
  `,
};

const PNLight = {
  fontFamily: 'Proxima-Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Proxima-Nova'),
    local('Proxima-Nova-Light'),
    url(${PNLightWOFF2}) format('woff2'),
    url(${PNLightOTF}) format('opentype')
  `,
};

const PNRegular = {
  fontFamily: 'Proxima-Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Proxima-Nova'),
    local('Proxima-Nova-Regular'),
    url(${PNRegularWOFF2}) format('woff2'),
    url(${PNRegularOTF}) format('opentype')
  `,
};

const PNSemibold = {
  fontFamily: 'Proxima-Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('Proxima-Nova'),
    local('Proxima-Nova-Semibold'),
    url(${PNSemiboldWOFF2}) format('woff2'),
    url(${PNSemiboldOTF}) format('opentype')
  `,
};

const PNBold = {
  fontFamily: 'Proxima-Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Proxima-Nova'),
    local('Proxima-Nova-Bold'),
    url(${PNBoldWOFF2}) format('woff2'),
    url(${PNBoldOTF}) format('opentype')
  `,
};

const PNBlack = {
  fontFamily: 'Proxima-Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local('Proxima-Nova'),
    local('Proxima-Nova-Black'),
    url(${PNBlackWOFF2}) format('woff2'),
    url(${PNBlackOTF}) format('opentype')
  `,
};

export {PNThin, PNLight, PNRegular, PNSemibold, PNBold, PNBlack};

