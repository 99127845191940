import React, { useEffect, useState } from "react";
import useSessionState from "../hooks/useSessionState";

const StateContext = React.createContext([{}, () => {}]);

const StateContextProvider = (props) => {
  const [state, setState] = useSessionState(props.sessionKey, props.initialState);

  return <StateContext.Provider value={[state, setState]}>{props.children}</StateContext.Provider>;
};

export { StateContext, StateContextProvider };
