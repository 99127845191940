import React, { useState, useEffect, Fragment } from "react";
import useScript from "../hooks/useScript";

import ChatBot from "../components/Chat/ChatBot";
import AppHeaderChat from "../components/Chat/AppHeaderChat";

const Chat = () => {  
  return (
    <Fragment>
      <AppHeaderChat />
      <ChatBot />
    </Fragment>
  );
};

export default Chat;
