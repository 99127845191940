import React, { useState, useEffect, useCallback } from "react";
import is_Empty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import clone from "lodash/clone";
import isNumber from "lodash/isNumber";
import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import axios from "axios";
import qs from "qs";
import useSWR, { useSWRInfinite } from "swr";
import useBoatsStateContext from "./useBoatsStateContext";
import { responsiveFontSizes } from "@material-ui/core";

const getBoats = async (url) => {
  const formatResult = () => {
    let newResults = [],
      numResults = 0;

    if (result && result?.data) {
      if ((result?.data?.results !== "undefinded") && (result?.data?.numResults > 0)) {
        newResults = result.data.results;
        numResults = result.data.numResults;
      } else if ((result?.data?.data?.results !== "undefined") && (result?.data?.data?.numResults > 0)) {
        newResults = result.data.data.results;
        numResults = result.data.data.numResults;
      }
    }

    return { data: newResults, total: numResults };
  };

  const result = await axios({
    baseURL: process.env.REACT_APP_BOATS_API_URL,
    method: "get",
    url: url,
  });

  return formatResult();
};

const getBoat = async (url) => {
  const formatResult = () => {

    if (result && result?.data) {
      if ((result?.data?.results !== "undefinded") && (result?.data?.numResults > 0)) {
        return result.data.results[0];
        
      } else if ((result?.data?.data?.results !== "undefined") && (result?.data?.data?.numResults > 0)) {
        return result.data.data.results[0];
      }
    }
  };

  const result = await axios({
    baseURL: process.env.REACT_APP_BOATS_API_URL,
    method: "get",
    url: url,
  });

  return formatResult();
};

const useInfiniteBoats = (source) => {
  const { getSearchUrl, getFiltersRequest } = useBoatsStateContext();
  
  const getInfiniteKey = (index, previousPageData) => {
    if (previousPageData && !previousPageData.data.length) return null;

    let request = {};

    request["rows"] = process.env.REACT_APP_PAGE_SIZE;
    request["offset"] = (index) ? index * parseInt(process.env.REACT_APP_PAGE_SIZE) : 0;

    Object.assign(request, getFiltersRequest());

    return getSearchUrl(source) + "?" + qs.stringify(request);
  }

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getInfiniteKey, getBoats);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");
  const isEmpty = ((Array.isArray(data)) && (data[0]?.data?.length === 0));
  const isReachingEnd = (isEmpty || (Array.isArray(data) && (data[data.length -1]?.data === null || data[data.length -1]?.data?.length === 0)) || (Array.isArray(data) && (data[0]?.total <= parseInt(process.env.REACT_APP_PAGE_SIZE) * size)));
  const isRefreshing = isValidating && data && data.length === size;

  return {
    data: data,
    isLoadingInitialData: isLoadingInitialData,
    isLoadingMore: isLoadingMore,
    isEmpty: isEmpty,
    isReachingEnd: isReachingEnd,
    isError: error,
    size: size,
    setSize: setSize,
  };
};

const useBoat = (source, documentID) => {
  const { getDetailsUrl } = useBoatsStateContext();

  const url = getDetailsUrl(source) + "?" + qs.stringify({DocumentID: documentID});

  const { data, error } = useSWR(url, getBoat);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};

export { useInfiniteBoats, useBoat };
