import React, { useState } from "react";

const NotificationContext = React.createContext([{}, () => {}]);

const NotificationContextProvider = props => {
  const [message, addMessage] = useState(null);

  return <NotificationContext.Provider value={[message, addMessage]}>{props.children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationContextProvider };
