import { useState, useEffect } from "react";

const useSessionState = (localStorageKey, defaultValue) => {
  const [value, setValue] = useState(
    JSON.parse(sessionStorage.getItem(localStorageKey)) || defaultValue
  );

  useEffect(() => {
    sessionStorage.setItem(localStorageKey, JSON.stringify(value))
  }, [value, localStorageKey]);

  return [value, setValue];
};

export default useSessionState;