import React, { useState, useEffect, Fragment } from "react";
import { SWRConfig } from "swr";

import Grid from "@material-ui/core/Grid";
import BoatsSaleCard from "../components/Home/BoatsSaleCard";
import OutboardsSaleCard from "../components/Home/OutboardsSaleCard";
import ServiceCard from "../components/Home/ServiceCard";
import ContactCard from "../components/Home/ContactCard";
import RentalsCard from "../components/Home/RentalsCard";
import ChatCard from "../components/Home/ChatCard";
import EventsCard from "../components/Home/EventsCard";
import useStyles from "../hooks/useStyles";
import AppHeaderHome from "../components/Home/AppHeaderHome";

const Home = () => {
  const classes = useStyles();
  const swrConfig = {
    revalidateOnFocus: false,
    refreshInterval: 10000,
  };

  return (
    <SWRConfig value={swrConfig}>
      <AppHeaderHome />
      <Grid container spacing={2} direction="row" justify="space-around">
        <Grid item xs={6}>
          <BoatsSaleCard slide="right" />
        </Grid>

        <Grid item xs={6}>
          <OutboardsSaleCard slide="left" />
        </Grid>

        <Grid item xs={6}>
          <ServiceCard slide="right" />
        </Grid>

        <Grid item xs={6}>
          <RentalsCard slide="left" />
        </Grid>

        <Grid item xs={6}>
          <EventsCard slide="right" />
        </Grid>

        <Grid item xs={6}>
          <ChatCard slide="left" />
        </Grid>
      </Grid>
    </SWRConfig>
  );
};

export default Home;
