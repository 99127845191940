import React, { useState, useEffect, Fragment } from "react";
import useScript from "../hooks/useScript";
import { Alert, AlertTitle } from '@material-ui/lab';

import Checkfront from "../components/Rentals/Checkfront";
import AppHeaderRentals from "../components/Rentals/AppHeaderRentals";

const Rentals = () => {
  const [loaded, error] = useScript("//cannonsmarina.checkfront.com/lib/interface--0.js");
  return (
    <Fragment>
      <AppHeaderRentals />
    {(loaded && !error) ? (<Checkfront />) : null}
    {(error) ? (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Checkfront is unavailable. Please try again later.
      </Alert>) : null}
    </Fragment>
  );

}

export default Rentals;